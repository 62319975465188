export default {
  PRODUCT_REPEATER_CLASS: 'productGrid',

  SEARCH_BOX_CLASS: 'form',
  SEARCH_BOX_FORM_CLASS: 'form-field',
  SEARCH_BOX_INPUT_CLASS: 'form-input',

  SEARCH_BOX_PLACEHOLDER: 'Search by Keyword, Item # or Model #...',

  FACET_TOGGLE_LABEL: 'Filter by vehicle',

  DISCARD_VEHICLE: 'Clear',
  CHANGE_VEHICLE: 'Change',
};
