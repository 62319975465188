const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const categorySelectionPageUrl = '/category-list/';
const brandSelectionPageUrl = '/brands/';

window.Convermax.filteredByVehicle = () => window.document.body.classList.contains('cm_filtered_by_vehicle');

const getFitmentSearchTitle = () =>
  window.location.pathname === categorySelectionPageUrl
    ? 'Categories'
    : window.location.pathname === brandSelectionPageUrl
      ? 'Brands'
      : 'Parts';

function responseHandler(response) {
  const { Facets } = response;

  const isFilteredByVehicle = !Facets.find(
    (f) => f.FieldName === 'FilterByVehicle' && f.Selection.length > 0,
  );

  window.document.body.classList.toggle('cm_filtered_by_vehicle', isFilteredByVehicle);

  return response;
}

export default {
  platform: 'bigcommerce',
  responseHandler,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  pagination: {
    edgeRange: 0,
    centralRange: 1,
    disableDelimeters: true,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    isVehicleSelectionIsolated: true,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    getFitmentSearchTitle,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: ['category', 'brand'].map((className) => `#cm_${className}`).join(),
      template: 'SearchPage',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    { name: 'SearchResult' },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: 'header .navPages-quickSearch .container',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_categories',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brands',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: {
        insertAfter: 'body.page-type_default .homeHero',
        class: 'cm_vehicle-widget__home_container container',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      columnBreakpoint: 760,
    },
    {
      name: 'FacetVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysColumnLayout: true,
      isAlwaysActive: true,
      facetToggleConfig: {
        isInverted: true,
        toggledFacet: { field: 'FilterByVehicle', term: 'False' },
        initToggled: window.location.pathname !== '/search',
      },
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      facetToggleConfig: {
        isInverted: true,
        toggledFacet: { field: 'FilterByVehicle', term: 'False' },
        initToggled: window.location.pathname !== '/search',
      },
    },
    {
      name: 'Garage',
      location: {
        firstChildOf: '.header .header-icons',
        wrapper: 'li',
        class: 'header-icon header-icon--garage inline-block pl-2',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      location: {
        insertBefore: 'body.page-type_product .productView-options #add-to-cart-wrapper',
      },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: 'body.page-type_product .tabs-contents #tab-warranty',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'VehicleWidgetDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
